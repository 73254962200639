.box-information {
  margin-left: 0.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }

  .box-information-title {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .box-information-content {

  }
}

h3.box-header {
  background-color: $darkAccentColor;
  color: $white !important;
  padding: 10px !important;
  border-radius: 7px;
  text-transform: uppercase !important;
  margin-bottom: 0.5rem !important;
}