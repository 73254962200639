@import "../fonts";
@import "../colors";

.tabs-wrapper {
  max-width: 100vw;

  .no-tab-selected {
    margin-left: 10px;
  }
}

.export {
  .tabs {
    li {
      font-size: 13px !important;
      min-width: 120px !important;
    }
  }
}

.tabs {
  margin: 0 0 10px;
  padding:  0;

  li {
    list-style-type: none;
    border: 2px solid $ceviBlue;
    background-color: white;
    border-radius: 6px;
    font-family: "OpenSans Light", serif;
    font-weight: bold;
    font-size: $text-size-normal;
    padding: 12.5px 30px 12.5px 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    display: inline-block;
    min-width: 150px;

    a {
      color: $ceviBlue;
    }

    &:hover, &.active {
      background-color: $ceviBlue;
      a {
        color: white;
      }
    }
  }
}