@import "../fonts";
@import "../colors";

.datatable-wrapper {
  padding: 10px;

  .noresult {
    background-color: $tableColor;
    padding: 1rem;
    font-family: "OpenSans Bold", serif;
  }
}

.datatable {
  .cell-header {
    font-family: "OpenSans Bold", serif;
  }

  .action > a {
    color: $ceviBlue;
    font-family: "OpenSans Light", serif;
    margin-right: 1.2rem;

    &.new {
      color: $ceviGreen;
    }

    &:not(.disabled):not(.info):hover {
      cursor: pointer;
    }
  }
}

nav {
  ul.pagination {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-top: 2.5rem;
    margin-bottom: .5rem;

    .page-link {
      position: relative;
      display: block;
      padding: .5rem .75rem;
      line-height: 1.25;
      color: $ceviBlue;
      background-color: white;
      border: 2px solid $ceviBlue;
      border-radius: 6px;
      font-family: "OpenSans Bold", serif;
      margin-right: .5rem;
      width: fit-content;
      &:hover {
        color: white;
        background-color: $ceviBlue;
      }
    }
    .active {
      .page-link {
        color: white;
        background-color: $ceviBlue;
      }
    }
  }
}