.download-button {
  border: 3px solid $darkAccentColor !important;
  background-color: $darkAccentColor !important;
  border-radius: 6px !important;
  font-family: "OpenSans Light", serif !important;
  font-weight: bold !important;
  padding: 7.5px 15px 7.5px 10px !important;
  display: inline-block !important;
  font-size: 13px !important;
  min-width: 120px !important;
  color: white !important;
  margin-bottom: 1rem !important;
}

.export  nav ul.pagination {
  margin-top: 1rem;
}

.export table {
  margin-bottom: 20px;
}

.header-export-table {
  margin-bottom: 20px;
  margin-left: 5px;
}